import {
  b64DecodeUnicode,
  reduceUserSegment,
} from 'components/integrations/Mixpanel/parseUserClient';
import {
  ReducedUserTokenState,
  UserTokenState,
  anonymousUser,
} from 'lib/utils/parseUser';

const parseUserV2 = (user_token?: string): ReducedUserTokenState => {
  const token = user_token;

  if (!token) return anonymousUser;

  try {
    const user: UserTokenState = JSON.parse(
      b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')),
    );

    return {
      auth_token: user.auth_token || null,
      id: user.id || null,
      external_id: user.external_id || null,
      package_name: user.package_name || null,
      email: user.email || null,
      first_name: user.first_name || null,
      last_name: user.last_name || null,
      role: user.role || 'guest',
      segment: user.segment || 'anonymous',
      reducedSegment: reduceUserSegment(user.segment),
      idDigest: user.id_digest || '',
    };
  } catch {
    return anonymousUser;
  }
};

export default parseUserV2;
